<!--协会业务-->
<template>
  <div class="businessDiv">
    <!--面包屑-->
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <!--内容部分-->
    <div class="news-div listDivMinHeight">
      <!--11.3，需求变动，改为放一张图片-->
      <!--<img src="@/assets/img/giAssociationBusiness.png" alt="" style="width: 1200px">-->

      <!--步骤2：创建容器-->
      <!--<div class="g6-y" id="testContainerG6" ref="testContainerG6"></div>-->
      <!--自定义节点-->
      <!--<div class="g6-y" id="testContainerG6New" ref="testContainerG6New"></div>-->

      <!--SimpleMindMap思维导图库-->
      <div id="mindMapContainer"></div>

    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import {getPageByCategoryId} from '@/utils/commonApi.js'
import textEllipsis from '@/components/textellipsis'
import G6 from '@antv/g6'; //步骤1：引入g6
import MindMap from "simple-mind-map"; //引入simple-mind-map库
import axios from 'axios'

export default {
  name: "business",
  components: { topPathList, textEllipsis },
  data() {
    return {
      //步骤3：准备数据
      /*graphData: {
        id: "node1",
        img: "http://192.168.13.30:9001/geo/test/path/2023/09/02/750408224792125440.jpg",
        type: 'image',//图片节点
        size: 50,
        children: [],
        childrenData: [
          {
            id: "node2",
            label: "人才培育服务平台",
            description: '通过开展“人才创新培育”活动，深化“产学研”' +
              '育才模式,建立山东省地理标志专家智库，推进企业、高效、科研院' +
              '所的深入合作，共同培育实用人才,通过“项目+基地”模式，与各高校' +
              '建立农产品研发、品牌打造、产品推广等定向人才培养项目，搭建起' +
              '“学院育才、学生成才、企业用才、持证上岗”的模式。',
          },
          {
            id: "node3",
            label: "金融资本服务平台",
            description: '建设数字农业数据服务平台，推动新技术在地标产业金融领域的应用推广。' +
              '规范互联网金融在地标产业地区的发展,积极运用大数据、区块链等技术，降低信贷风险。' +
              '加强农信贷数据的积累和共享，通过客户信息整合和筛选，创新地标经营主体信用评价模式,' +
              '逐步提升发放信用贷款的比重。',
          },
          {
            id: "node4",
            label: "认证咨询溯源平台",
            description: '为地标企业提供产品溯源、产品质量、企业管理等多项认证服务,为企业的健康发展和地理标志产品的质量提升做好基础服务。',
          },
          {
            id: "node5",
            label: "检验监测服务平台",
            description: '为解决地理标志产品质量检测及特殊营养成分检测，协会通过链接各大检测机构,为地理标志企业提供专业化程度高、技术含量高的高附加值服务。',
          },
          {
            id: "node6",
            label: "地方工作服务平台",
            description: '充分发挥各办事处和各领域顾问团队的作用,协会每年举办专家论坛和各地区对接活动，' +
              '通过各办事处更加直接和便捷的为全省地标企业服务。组织开展各地区会员之间的现场观摩和学习交流活动,' +
              '推广先进经验，结成对子,相互帮扶、相互交流，促进发展,提高管理水平。',
          },
          {
            id: "node7",
            label: "政策宣传服务平台",
            description: '协会加强与政府、机构、团体等之间沟通搭建各种合作服务平台,为会员提供多种公共服务' +
              '、商务服务、政策扶持服务、投资项目服务，积极协调链接各种资源，争取各级政府的支持指导，' +
              '争取各行业的参与和对接,形成效益最大化，同时指导企业用足用好各级的扶持政策,紧紧抓住发展机遇。',
          },
          {
            id: "node8",
            label: "国际合作服务平台",
            description: '组织会员开展国内外交流活动、商务考察和参展组展活动,组织开展协会和' +
              '地理标志产业文化建设和文化交流活动,充分展示协会及会员企业形象和产品，' +
              '在更广阔的领域和更高层面发展推广,开拓市场。',
          },
          {
            id: "node9",
            label: "知识产权服务平台",
            description: '宣传介绍有关地理标志的申请认定、知识产权等实务知识，做好知识产权保护与运用的战略规划,提升以山东地理标志产业为基点的全球化发展。',
          },
          {
            id: "node10",
            label: "品牌运营服务平台",
            description: '开展品牌创建工作,提供市场调研、形象策划、包装设计、活动组织等服务，' +
              '帮助其提升地理标志产品的品牌知名度、品牌IP的打造及文创产品研发;新媒体矩阵搭建与数字媒体全领域营销。',
          },
          {
            id: "node11",
            label: "法律咨询与维权服务平台",
            description: '针对知识产权维权保护问题，提供知识产权保护支持及法律维权服务，保护地标产品声誉，维护地标企业权益。',
          },
          {
            id: "node12",
            label: "标准建设与实施服务平台",
            description: '为企业梳理标准，通过企业标准、团体标准、地方标准、行业标准、国家标准等,提升规范化运营，在节约能源的同时保证产品质量，消除贸易障碍、促进贸易发展。',
          },
          {
            id: "node13",
            label: "转移转化服务平台",
            description: '打造全国一流的项目转移转化服务平台，全面做好优势特色产业集群、' +
              '现代农业产业园、特色农产品优势区、田园综合体、乡村振兴齐鲁样板示范区、' +
              '地理标志保护示范区等重点项目。',
          },
          {
            id: "node14",
            label: "乡村振兴服务平台",
            description: '通过地理标志助推乡村振兴,聚集巩固拓展脱贫攻坚成果与助推乡村振兴有效衔接，' +
              '围绕地理标志不断延长产业链、完善供应链、提升价值链,推动地理标志产品在更高层次、' +
              '更广范围惠及更多农民。',
          },
          {
            id: "node15",
            label: "文化赋能服务平台",
            description: '积极推动将地理标志文化纳入城市形象宣传、双招双引推介和广播电视、' +
              '新闻出版、影视创作、民俗文化等各个方面,广泛开展地理标志产品和产业宣传推介。' +
              '同事，大力推广、创造文创产品，真正将山东好品推向全国。',
          },
        ]
      },*/
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标协会',
        },
        {
          name: '协会业务',
          path: '/giia/business'
        },
      ],
      noticeList: [],
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 10,
      categoryId: "", //接收路由传过来的栏目id
      //后台返回数据
      children: [],
    }
  },
  mounted () {
    this.categoryId = this.$route.query.categoryId
    //this.getList()
    //this.initGraph()
    //this.initGraph2()
    this.getMindData()
  },
  methods: {
    //获取后台返回思维导图数据
    getMindData() {
      axios({
        method: 'post',
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/backgrounduphold/GiAssBusiness/hussarQueryGiAssBusinessCondition_1GiAssBusinessSort_1',
        data: {giAssBusinessupholdTitleEqual: ''},
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          let arr = res.data.data.data
          //newArr抽取接口中的标题和内容
          let newArr = []
          arr.forEach(item => {
            newArr.push({ title: item.upholdTitle, text: item.content });
            })
          //arr2将内容newArr中的内容放在data对象中
          let arr2 = []
          newArr.forEach(item => {
            arr2.push({ data: item });
          })
          this.children = arr2

          this.initSimpleMindMap()

        } else {
          this.$message.warning('请求失败')
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    initSimpleMindMap() {
      //后台返回的处理好的数据
      let arr = []
      arr = this.children
      const mindMap = new MindMap({
        //自定义节点
        isUseCustomNodeContent: true,
        customCreateNodeContent: (node) => {
          //如果是根节点
          if (node.nodeData.data.image) {
            //return null
            /*采用SimpleMindMap内置图片节点宽高一直是100，没有调节成功，故采用下方自定义节点调节图片大小*/
            let div = document.createElement('div')
            div.className = 'imgNodeClass'
            div.style.cssText = `width:170px; height:170px;`
            div.innerHTML = `
          <img src="https://www.zgdbcy.com/wx/images/images/xiehuiyewu.png" alt="" style="width: 170px; height: 170px">
      `
            return div
          } else {
            // return你的自定义DOM节点
            let div = document.createElement('div')
            div.className = 'nodeClass'
            div.style.cssText = `width:400px; height:180px; border-radius: 8px; border-left: solid 6px #5794f8;`
            div.innerHTML = `
          <span style="float:left; text-align: left; font-weight: bold; margin: 10px 0 10px 10px; border-bottom: solid 2px #77a8f9; box-shadow: 0px 2px 20px 0px rgba(34,80,158,0.06);">${ node.nodeData.data.title }</span>
          <p style="clear: both; text-align: left; margin: 0 10px 10px 10px;">${ node.nodeData.data.text }</p>
      `
            return div
          }
        },

        //鼠标滚动，为了让鼠标在图上滚动失效
        customHandleMousewheel: (e) => {
          // 你的自定义逻辑
        },
        el: document.getElementById('mindMapContainer'),
        layout: 'mindMap', //脑图布局
        mousewheelAction: '',// 鼠标滚轮事件：zoom（放大缩小）、move（上下移动）
        disableMouseWheelZoom: 'false', //禁止鼠标滚轮缩放
        readonly: 'true', //只读
        enableCtrlKeyNodeSelection: 'false', //按住ctrl多选节点功能
        initRootNodePosition: ['43%', '44%'], //初始根节点的位置
        data: {
          data: {
            // 图片
            image: 'https://www.zgdbcy.com/wx/images/images/xiehuiyewu.png',
            imageSize: { //超过了100就不起作用
              width: 150,
              height: 150,
            },
          },
          children: arr
        },
      });

      mindMap.setThemeConfig({
        lineColor: '#77a8f9', //连线颜色
        lineWidth: 3, //连线宽度
        lineStyle: 'curve', //曲线
        // 根节点样式
        root: {
          fillColor: 'transparent',
          borderColor: '',
          borderWidth: 0,
          shape: 'circle',
        },
        // 二级节点样式
        second: {
          fillColor: 'rgb(255, 255, 255)',
          borderColor: '#77a8f9',
          borderWidth: 1,
        },
      })
    },

    //初始化关系图，并渲染数据
    /*initGraph() {
      const container = document.getElementById('testContainerG6');
      const width = container.scrollWidth;
      const height = container.scrollHeight || 500;
      const graph = new G6.TreeGraph({
        container: container,
        width,
        height,
        modes: {
          default: [
            {
              type: 'collapse-expand',
              onChange: function onChange(item, collapsed) {
                const data = item.getModel();
                data.collapsed = collapsed;
                return true;
              },
            },
          ],
        },
        defaultNode: {
          type: 'modelRect',
          size: [230, 80],
          labelCfg: {
            //position: 'top',
            offset: 10,
            style: {
              fill: '#3a3a3a',
              fontSize: 8,//label的字体大小
            },
          },
          logoIcon: {
            show: false, //前面的icon不显示
          },
          stateIcon: {
            show: false, //后面的icon不显示
          },
          preRect: {
            // 设置为 false，则不显示
            show: true,
            fill: '#77a8f9',
            width: 4,
          },
          descriptionCfg: {
            paddingTop: 20,
            style: {
              fill: '#919191',
              fontSize: 7,//label的字体大小
            },
          },
          anchorPoints: [ //节点连接点
            [0, 0.5],
            [1, 0.5],
          ],
          style: {
            stroke: '#ffffff', //节点的描边颜色
            shadowColor: '#edeff2', //阴影颜色
          },
        },
        defaultEdge: { //默认边：水平方向的三阶贝塞尔曲线
          type: 'cubic-horizontal',
          style: {
            stroke: '#bdd4f8',
          }
        },
        layout: {
          type: 'mindmap',
          direction: 'H',
          getHeight: () => { //节点高度
            return 50;
          },
          getWidth: () => { //节点宽度
            return 90;
          },
          getVGap: () => { //节点纵向间距
            return 30;
          },
          getHGap: () => { //节点横向间距
            return 60;
          },
        },
      })

      let centerX = 0;
      graph.node(function (node) {
        if (node.id === 'node1') {
          centerX = node.x;
          return {
            clipCfg: { //图片裁剪为圆形
              show: true,
              type: 'circle',
              // circle
              r: 25,
            }
          }
        }
        return {
          label: node.label,
          /!*labelCfg: {
            position:
              node.children && node.children.length > 0
                ? 'left'
                : node.x > centerX
                ? 'right'
                : 'left', //文本想归于节点的位置，modelRect节点不支持该属性
            offset: 5, //文本的偏移
          },*!/

        };

      });

      this.graphData.childrenData.forEach((item) => {
        let obj1 = {}
        obj1 = {
          id: item.id,
          label: item.label,
          description: this.superLongTextHandle(item.description, 380, 12),
        }
        this.graphData.children.push(obj1)
      })

      graph.data(this.graphData);
      graph.render();
      graph.fitView();

    },*/

    // G6换行符处理超长文本，用于描述部分换行
    /*superLongTextHandle(str, maxWidth, fontSize) {
      let currentWidth = 0;
      let res = str;
      // 区分汉字和字母
      const pattern = new RegExp("[\u4E00-\u9FA5]+");
      str.split("").forEach((letter, i) => {
        if (currentWidth > maxWidth) return;
        if (pattern.test(letter)) {
          // 中文字符
          currentWidth += fontSize;
        } else {
          // 根据字体大小获取单个字母的宽度
          currentWidth += G6.Util.getLetterWidth(letter, fontSize);
        }
        if (currentWidth > maxWidth) {
          res = `${str.substr(0, i)}\n${this.superLongTextHandle(
            str.substr(i),
            maxWidth,
            fontSize
          )}`;
        }
      });
      return res;
    },*/

    //尝试自定义节点
    /*initGraph2() {
      const textXML = (cfg) => `
      <rect style={{
        width: 230, height: 80, fill: '#ffffff', stroke: '#1890ff', radius: 5
      }}>
      <rect style={{
      width: 230, height: 10,
      }}>
      <text style={{ marginTop: 10, marginLeft: 40,
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '10'
          fill: '#4d4d4d' }}>
            ${cfg.label}
        </text>
      </rect>
      <text style={{ marginTop: 55, marginLeft: 5,
          fontSize: '8'
          fill: '#8c8c8c' }}>
            ${cfg.description}
       </text>
      </rect>
    `;

      G6.registerNode('hzh', {
        jsx: textXML,
      });


      const container = document.getElementById('testContainerG6New');
      const width = container.scrollWidth;
      const height = container.scrollHeight || 500;
      const graph = new G6.TreeGraph({
        container: container,
        width,
        height,
        modes: {
          default: [
            {
              type: 'collapse-expand',
              onChange: function onChange(item, collapsed) {
                const data = item.getModel();
                data.collapsed = collapsed;
                return true;
              },
            },
          ],
        },
        defaultNode: {
          type: 'hzh',
        },
        defaultEdge: { //默认边：水平方向的三阶贝塞尔曲线
          type: 'cubic-horizontal',
          style: {
            stroke: '#bdd4f8',
          }
        },
        layout: {
          type: 'mindmap',
          direction: 'H',
          getHeight: () => { //节点高度
            return 30;
          },
          getWidth: () => { //节点宽度
            return 20;
          },
          getVGap: () => { //节点纵向间距
            return 50;
          },
          getHGap: () => { //节点横向间距
            return 180;
          },
        },
      })

      let centerX = 0;
      graph.node(function (node) {
        if (node.id === 'node1') {
          centerX = node.x;
          return {
            clipCfg: { //图片裁剪为圆形
              show: true,
              type: 'circle',
              // circle
              r: 25,
            }
          }
        }
        return {
          label: node.label,
          /!*labelCfg: {
            position:
              node.children && node.children.length > 0
                ? 'left'
                : node.x > centerX
                ? 'right'
                : 'left', //文本想归于节点的位置，modelRect节点不支持该属性
            offset: 5, //文本的偏移
          },*!/

        };

      });

      this.graphData.childrenData.forEach((item) => {
        let obj1 = {}
        obj1 = {
          id: item.id,
          label: item.label,
          description: this.superLongTextHandle(item.description, 320, 12),
        }
        this.graphData.children.push(obj1)
      })

      graph.data(this.graphData);
      graph.render();
      graph.fitView();

    },*/

    /*getList() {
      let params = {
        ...this.$route.query,
        current: this.page,
        size: this.pageSize,
      }
      getPageByCategoryId(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.tableTotal = res.data.data.total
          this.noticeList = res.data.data.records
          /!*日期显示年月日*!/
          for (let i = 0; i < this.noticeList.length; i++) {
            this.noticeList[i].contentEditTime = this.noticeList[i].contentEditTime.substring(0,10)
          }
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },*/
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';

  .g6-y {
    width: 1200px;
    min-height: 2000px;
    margin-left: 360px;
  }

  #mindMapContainer {
    width: 1200px;
    min-height: 1600px;
    background-color: #ffffff !important;
    margin: auto;
    pointer-events: none; //禁止鼠标拖动
  }

  #mindMapContainer * {
    margin: 0;
    padding: 0;
  }

  .businessDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .news-div {
    min-height: 487px;
    margin: auto;
    padding: 10px;
    width: 1200px;
    background: #FFFFFF;
    .notice-item {
      width: 1160px;
      margin: 0px auto 29px;
      padding: 13px 16px;
      background: #FFFFFF;
      border-radius: 5px;
      height: 161px;
      .notice-item-img {
        float: left;
        img {
          width: 270px;
          height: 160px;
        }
      }
      .rightContent {
        text-align: left;
        margin-left: 311px;
        margin-top: 17px;
        .notice-item-title {
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          line-height: 27px;
          overflow: hidden;//超出隐藏
          text-overflow: ellipsis;//显示省略号
          white-space: nowrap;//禁止文本自动换行
        }
        .notice-item-content {
          margin-top: 12px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
        .notice-item-date {
          margin-top: 18px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ABB2BF;
          line-height: 21px;
          .imgClass {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
    }
    .notice-item:hover {
      cursor: pointer;
    }
  }
  .page {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1366px) {
    .businessDiv {
      text-align: center;
      background-color: #f8f9fa !important;
    }
    .breadTop {
      width: 1200px;
      margin: auto;
    }
    .news-div {
      .notice-item {
        width: 1160px;
        margin: 0px auto 29px;
        padding: 13px 16px;
        background: #FFFFFF;
        border-radius: 5px;
        height: 161px;
        .notice-item-img {
          float: left;
          img {
            width: 270px;
            height: 160px;
          }
        }
        .rightContent {
          text-align: left;
          margin-left: 311px;
          margin-top: 17px;
          .notice-item-title {
            font-size: 18px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 27px;
            overflow: hidden;//超出隐藏
            text-overflow: ellipsis;//显示省略号
            white-space: nowrap;//禁止文本自动换行
          }
          .notice-item-content {
            margin-top: 12px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 23px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
          .notice-item-date {
            margin-top: 18px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ABB2BF;
            line-height: 21px;
            .imgClass {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
        }
      }
      .notice-item:hover {
        cursor: pointer;
      }
    }
    .page {
      margin-bottom: 20px;
    }
  }
</style>
